/** routes **/
import {auth} from '@/router/modules/auth'
import {users} from '@/router/modules/users'
import {unverifiedUsers} from '@/router/modules/unverifiedUsers'
import {admins} from '@/router/modules/admins'
import {disputes} from '@/router/modules/disputes'
import NotFound from '@/views/NotFound'
import Logout from "@/views/Logout";
import ActivateAdmin from "@/views/admins/ActivateAdmin";

export const ROUTE_NAMES_ROOT = Object.freeze({
  LOGOUT: 'Logout',
  NOT_FOUND: 'Not Found',
  ACTIVATE_ADMIN: 'ActivateAdmin'
});

/** routes without layout **/
export const root = [
  {
    path: '/logout',
    name: ROUTE_NAMES_ROOT.LOGOUT,
    component: Logout
  },
  {
    // not found
    path: '/not-found',
    name: ROUTE_NAMES_ROOT.NOT_FOUND,
    component: NotFound
  },
  {
    path: '/cms-user/activate/:id/:token',
    name: ROUTE_NAMES_ROOT.ACTIVATE_ADMIN,
    component: ActivateAdmin
  }
]

export default root.concat([auth, users, unverifiedUsers, admins, disputes]);
